<template>
	<div class="page-container" id="live-calendar">
		<div class="calendar">
			<div class="calendar-component container">
				<div class="calendar-container">
					<div class="calendar-row">

						<div v-for="(date, idx) in dateArray"
                 :data-date="date"
                 :data-idx="idx"
                 class="day"
                 :class="{
                   'is-selected':date.getDate() === selectDay.getDate(),
                   'is-today':date.getDate() === today.getDate() && date.getDate() !== selectDay.getDate()
                 }"
                 @click="onClickDate"
                 :key="idx">
							<button type="button" :data-date="date" :data-idx="idx" class="day-link" @click="onClickDate">
                <span :data-date="date" :data-idx="idx" class="date" @click="onClickDate">{{date.getDate()}}</span>
                <span :data-date="date" :data-idx="idx" class="text" @click="onClickDate">{{week[date.getDay()]}}</span>
              </button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="live-schedule">
			<div class="schedule-component container">
				<ul class="schedule-list">
          <li v-for="(brcInfo, idx) in brcInfoList" class="schedule-item" :key="idx">
            <div class="item-live"  :data-brcinfo="JSON.stringify(brcInfo)" @click="onBrcSchdgClick">
              <article class="live-card" :class="brcInfo.BRC_STS_CD === '10'? 'is-onair' : brcInfo.BRC_STS_CD === '20' ? 'is-end' : 'is-soon'">
                <div class="live-video">
                  <div v-if="brcInfo.BRC_STS_CD === '10'">
                    <div class="video-watch">
                      <i class="icon-watch"></i>
                      <span class="text">{{brcInfo.INQ_CNT}}</span>
                    </div>
                    <div class="video-onair">
                    </div>
                  </div>
                  <div v-else>
                    <div class="video-onair">
                    </div>
                    <div class="video-playtime">
                      <span class="text">{{brcInfo.DURATION}}</span>
                    </div>
                  </div>
                  <div class="video-player">
                    <button type="button" class="link-player">
                      <img :src=brcInfo.BRC_RPRS_IMG_URL @error="handleImgError" alt=""/>
                    </button>
                  </div>
                </div>
              </article>
            </div>
            <div class="item-content">
              <div class="item-badge">
                <i class="video-badge"
                   :class="{
                      'video-badge-comingsoon': brcInfo.BRC_STS_CD === '07' || brcInfo.BRC_STS_CD === '08' || brcInfo.BRC_STS_CD === '09',
                      'video-badge-live': brcInfo.BRC_STS_CD === '10',
                      'video-badge-vod': brcInfo.BRC_STS_CD === '20',
                    }">
                </i>
              </div>
              <div class="item-time"><span class="text">{{brcInfo.BRC_SCHDG_BGNG_DT != null ? brcInfo.BRC_SCHDG_BGNG_DT.substring(11, 16) : ""}}</span></div>
              <div class="item-product">
                <article class="product-card is-row">
                  <div class="product-title v-md pointer" :data-brcinfo=JSON.stringify(brcInfo) @click="onBrcSchdgClick">
                    <div class="title-link"><span class="text">{{brcInfo.BRC_TIT}}</span></div>
                  </div>
                  <div class="product-image" :data-gdid="brcInfo.GDS_ID" :data-gdspcomurl="brcInfo.GDS_PCOM_URL" :data-brcinfono="brcInfo.BRC_INFO_NO">
                    <a :href="brcInfo.GDS_PCOM_URL" class="image-link"><img :src="brcInfo.GDS_IMG_URL" @error="handleImgError"  alt=""/></a>
                    <div class="image-badge"></div>
                  </div>
                  <div class="product-info">
                    <div class="title">
                      <div class="title-link pointer" :data-brcinfo="JSON.stringify(brcInfo)" @click="onBrcSchdgClick">
                        <span class="text">{{brcInfo.BRC_TIT}}</span>
                      </div>
                      <a :href="brcInfo.GDS_PCOM_URL"><p class="subtext" :data-gdsid="brcInfo.GDS_ID" :data-gdspcomurl="brcInfo.GDS_PCOM_URL" :data-brcinfono="brcInfo.BRC_INFO_NO">{{brcInfo.GDS_NM}}</p></a>
                    </div>
                    <div class="price" :data-gdsid="brcInfo.GDS_ID" :data-gdspcomurl="brcInfo.GDS_PCOM_URL" :data-brcinfono="brcInfo.BRC_INFO_NO" >
                      <span class="price-current">{{addThousandComma(brcInfo.GDS_PRC)}}원</span>
                    </div>
                  </div>
                </article>
                <div class="shopping-bottom">
                  <div class="bottom-seller"><span class="text">{{ brcInfo.SUPP_NM }}</span></div>
                </div>
              </div>
              <div v-if="parseInt(brcInfo.BRC_STS_CD) > 10" class="item-actions">
                <button type="button" class="btn btn-outline-light btn-actions" disabled="">
                  <span class="text">라이브종료</span>
                </button>
              </div>
              <div v-else class="item-actions">
                <toggle-switch
                    id="sbptn_switch"
                    :propModel="sbptnInfo"
                    :value="'Y'"
                    class="color-orange"
                    label="알림받기"
                    @changefunc="sbptnChange()"
                />
              </div>
            </div>
          </li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import ToggleSwitch from '../../../components/common/ToggleSwitch';
import {dateToDateString, getAddDay} from "@/assets/js/DateUtils";
import {ACT_GET_LIVE_COMMERCE_LIST, ACT_GET_LIVE_SBPTN, ACT_SAVE_LIVE_SBPTN} from "@/store/_act_consts";
import {mapGetters} from "vuex";
import {getItem} from "@/assets/js/utils";
import {MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM} from "@/store/_mut_consts";

export default {
	name: 'LiveSchedule',
	components: { ToggleSwitch },
	data: () => ({
    toggle1: [],
    toggle2: [],
    today: new Date(),
    week: ['일', '월', '화', '수', '목', '금', '토'],
    dateArray:[],
    selectDay:null,
    brcInfoList: [],
    sbptnInfo: [],
  }),
	computed: {
    ...mapGetters('auth', ['session', 'isAuth']),
  },
	watch: {
    session: function (newVal) {
      if (!!newVal && !!newVal.userId) {
        this.getBrcLiveInfo();
      }
    },
  },
	created() {},
	mounted() {
    this.setSchdgDate();
    this.getBrcLiveInfo();
  },
	methods: {
    getBrcLiveInfo() {
      this.getBrcScheduleList();
      this.getLiveSbptn();
    },
    getBrcScheduleList() {
      this.$store
          .dispatch(`broadcast/${ACT_GET_LIVE_COMMERCE_LIST}`, {
            sortCd: '05',
            schdgYn: 'Y',
            brcSchdgDate: dateToDateString(new Date(this.selectDay))
          })
          .then((res) => {
            this.brcInfoList = res.broadcasts.LIST;
          })
          .catch(() => {
            this.brcInfoList = [];
          });
    },
    getLiveSbptn() {
      this.$store
          .dispatch(`sbptn/${ACT_GET_LIVE_SBPTN}`, {
            userId: this.session.userId,
            sbptnTrgtTypCd: "300",
          })
          .then((res) => {
            const sbptnYn = getItem(res)?.useYn || "N";
            this.sbptnInfo = sbptnYn === "Y" ? [sbptnYn] : ["N"];
          });
    },
    onBrcSchdgClick(e) {
      const brcInfo = JSON.parse(e.currentTarget.dataset.brcinfo);
      const brcInfoNo = brcInfo.BRC_INFO_NO;
      if (parseInt(brcInfo.BRC_STS_CD) < 10) {
        // coming soon
        this.$router.push({ name: 'LiveComing', params: { brcInfoNo: brcInfoNo }});
      } else if (parseInt(brcInfo.BRC_STS_CD) === 20) {
        // vod
        this.$router.push({ name: 'LiveVodView', params: { brcInfoNo: brcInfoNo }});
      } else {
        // live
        if (brcInfo.VRT_YN === 'N') {
          this.$router.push({ name: 'LiveView', params: { brcInfoNo: brcInfoNo }});
        } else {
          this.$router.push({ name: 'LiveView', params: { brcInfoNo: brcInfoNo }});
        }
      }
    },
    setSchdgDate() {
      const startDate = getAddDay(new Date(), -5);
      const dateArrayTmp = [];

      for (let i = 0; i < 12; i++)
      {
        dateArrayTmp.push(getAddDay(startDate, i));
      }
      this.dateArray = dateArrayTmp
      this.selectDay = this.today
    },
    onClickDate(e) {
      // const idx = e.target.dataset.idx;
      this.selectDay = new Date(e.target.dataset.date);
      this.getBrcLiveInfo();
    },
    sbptnChange() {
      if (this.isAuth) {
        let reqBody = {
          userId: this.session.userId,
        }
        if (this.sbptnInfo[0] === "Y") {
          const param = {
            html: true,
            title: '알림을 해제하시겠습니까?',
            showCancelButton: true,
            yesfunc: () => {
              reqBody["useYn"] = "N";
              this.saveLiveSbptn(reqBody);
            },
            nofunc: () => {
              document.getElementById("sbptn_switch").checked = true;
            }
          };
          this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
        }
        // 신규 및 수정
        else {
          reqBody["useYn"] = "Y";
          this.saveLiveSbptn(reqBody);
        }
      } else {
        this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
          title: '회원가입 및 로그인이 필요합니다.',
          html: true,
          showCancelButton: true,
          yesfunc: () => {
            this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
            this.$router.push({name:'Login'});
          },
        });
      }
    },
    saveLiveSbptn(reqBody) {
      this.$store
        .dispatch(`sbptn/${ACT_SAVE_LIVE_SBPTN}`, reqBody)
        .then((res) => {
          if (res.result.success) {
            this.sbptnInfo = [reqBody.useYn];
          }
          else {
            console.info(res.result.message);
          }
        })
        .catch((err) => console.log(err));
    },
    handleImgError(e) {
      e.target.src = errorImg;
    },
    addThousandComma(number) {
      if (typeof number === 'undefined' || number === null) {
        return 0;
      }
      const numberStr = number.toString();
      if (numberStr.length <= 3) {
        return numberStr;
      } else if (numberStr.substring(0, 1) === '-' && numberStr.length === 4) {
        return numberStr;
      } else {
        let commaNumberStr = '';
        let i;
        for (i = numberStr.length - 3; i > 0; i = i - 3) {
          commaNumberStr = ',' + numberStr.substring(i, i + 3) + commaNumberStr;
        }
        if (i + 3 > 0) {
          commaNumberStr = numberStr.substring(0, i + 3) + commaNumberStr;
        }
        return commaNumberStr; //number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","); IE11 및 사파리에서 정상 동작 안함
      }
    },
  },
};
</script>
